<template>
  <v-container fluid v-if="!id || !value || id !== value._id"></v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="12">
        <h2>{{ value.person | person }} ({{ value.team.name }})</h2>
      </v-col>
      <v-col order-xl="1" xl="6" cols="12">
        <v-col v-if="kari_e1" cols="12"><punktwaehler v-model="value.e1" :label="`E1 (${kari_e1})`" /></v-col>
        <v-col v-if="kari_e2" cols="12"><punktwaehler v-model="value.e2" :label="`E2 (${kari_e2})`" /></v-col>
        <v-col v-if="kari_e3" cols="12"><punktwaehler v-model="value.e3" :label="`E3 (${kari_e3})`" /></v-col>
        <v-col v-if="kari_e4" cols="12"><punktwaehler v-model="value.e4" :label="`E4 (${kari_e4})`" /></v-col>
      </v-col>
      <v-col order-xl="3" xl="6" cols="12">
        <v-col cols="12"><punktwaehler v-model="value.dscore" label="D-Note" /></v-col>
        <v-col cols="12"><punktwaehler v-model="value.penalty" label="Penalty" /></v-col>
        <v-col v-if="istSprung(did)" cols="12"><i>Hinweis: bei einem ungültigen 0-Punkte Sprung sämtliche Werte auf 0,0 belassen!</i></v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :value="value.dscore | float2_0"
          label="D-Note"
          readonly
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="escore | float2_0"
          label="E-Note"
          readonly
        >
          <template #prepend>+</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="value.penalty | float2_0"
          label="Penalty"
          readonly
        >
          <template #prepend>-</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="final | float2_0"
          label="Endnote"
          readonly
          style="font-size: 150%;"
        >
          <template #prepend>=</template>
        </v-text-field>
      </v-col>
      <v-col cols="12" style="text-align: right;">
        <v-btn @click="del" style="margin-right:16px;">Löschen</v-btn>
        <v-btn @click="save" style="margin-right:16px;">Speichern und weiter</v-btn>
        <v-btn @click="close">Schließen</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'
import { resultquery } from '@/views/components/stb_w_2023/content/input/query'
import gql from 'graphql-tag'

export default {
  name: 'ergebnis',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  components: {
    Punktwaehler: () => import('./punktewaehler')
  },

  props: {
    id: {
      type: String,
      default: null
    },
    did: {
      type: String,
      default: null
    },
    e: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    value: {},
    Result: {}
  }),

  computed: {
    inline () {
      return !!['xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    kari_e1 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e1')) || null
    },
    kari_e2 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e2')) || null
    },
    kari_e3 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e3')) || null
    },
    kari_e4 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e4')) || null
    },
    deduction () {
      if (this.istSprung(this.did) && this.value.dscore === 0) return 0

      const data = this.value

      const d = []

      if (this.kari_e1) d.push(data.e1)
      if (this.kari_e2) d.push(data.e2)
      if (this.kari_e3) d.push(data.e3)
      if (this.kari_e4) d.push(data.e4)

      d.sort((a, b) => a < b ? -1 : 1)

      if (d.length === 0) return 0
      if (d.length === 1) return d[0]
      if (d.length === 2) return Math.round((d[0] + d[1]) * 50) / 100
      if (d.length === 3) return Math.round((d[0] + d[1] + d[2]) * 33.333) / 100
      if (d.length === 4) return Math.round((d[1] + d[2]) * 50) / 100

      return 0
    },
    escore () {
      if (this.istSprung(this.did) && !this.value.dscore) return 0
      return Math.round((10 - this.deduction) * 100) / 100
    },
    final () {
      if (this.istSprung(this.did) && this.value.dscore === 0) return 0
      return Math.max(0, Math.round((this.value.dscore + this.escore - this.value.penalty) * 100) / 100)
    },
  },

  methods: {
    del() {
      this.$emit('del')
    },
    save() {
      this.$emit('save', {
        ...this.value,
        deduction: this.deduction,
        escore: this.escore,
        final: this.final
      })
    },
    close() {
      this.$emit('close')
    }
  },

  apollo: {
    Result: {
      query: gql`query($id: UUID!) {
        Result(id: $id) { ${resultquery} }
      }`,
      variables () {
        return {
          id: this.id
        }
      },
      skip () {
        return !this.id
      },
      result ({ data }) {
        if (!data) return
        this.value = JSON.parse(JSON.stringify(data.Result))
        return null
      }
    }
  }
}
</script>

<style scoped>
</style>
